<template>
  <div id="app">
    <div class="content-holder">
      <div class="section">
        <div class="container">
          <div class="logo">
            <a href="https://astmarket.com/" target="_blank">
              <img src="./assets/images/logos/ast-market-logo.png" alt="ast-market-logo.png">
            </a>
          </div>
          <h3 class="section-title">Наши бренды</h3>
          <div class="brands-list">
            <div class="brands-list-item">
              <a href="https://astix.info/" target="_blank">
                <img src="./assets/images/logos/astix-logo.png" alt="astix-logo.png">
              </a>
            </div>
            <div class="brands-list-item">
              <a href="https://gurman-tech.ru/" target="_blank">
                <img src="./assets/images/logos/gurman-logo.png" alt="gurman-logo.png">
              </a>
            </div>
            <div class="brands-list-item">
              <a href="https://vakssplit.info/" target="_blank">
                <img src="./assets/images/logos/valks-logo.png" alt="valks-logo.png">
              </a>
            </div>
            <div class="brands-list-item">
              <a href="https://www.mojo-bt.ru/" target="_blank">
                <img src="./assets/images/logos/MOJO-logo.png" alt="MOJO-logo.png">
              </a>
            </div>
            <div class="brands-list-item">
              <a href="https://holleberg.com/" target="_blank">
                <img src="./assets/images/logos/holleberg-logo.png" alt="holleberg-logo.png">
              </a>
            </div>
            <div class="brands-list-item jaco-logo">
              <a href="https://jacoo.info/" target="_blank">
                <img src="./assets/images/logos/jaco-logo.png" alt="jaco-logo.png">
              </a>
            </div>
          </div>
          <p class="brands-footer">Внимание! Указанные цены являются розничными.</p>
        </div>
      </div>
      <div class="section grey">
        <div class="container">
          <h3 class="section-title">Розничный сайт наших магазинов</h3>
          <a href="https://astmarket.com/" target="_blank" class="link">
            <img class="icon" src="./assets/images/icons/link-solid.svg" alt="link-solid.svg">
            <span>https://astmarket.com/</span>
          </a>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <h3 class="section-title">Мы в социальных сетях</h3>
          <div class="socials-list">
            <div class="socials-list-item">
              <a href="https://vk.com/astmarket" target="_blank">
                <img class="button-icon" src="./assets/images/icons/vk-brands.svg" alt="vk-brands.svg">
              </a>
            </div>
            <div class="socials-list-item">
              <a href="https://ok.ru/group/53565116055741" target="_blank">
                <img class="button-icon" src="./assets/images/icons/square-odnoklassniki-brands.svg" alt="square-odnoklassniki-brands.svg">
              </a>
            </div>
            <div class="socials-list-item">
              <a href="https://www.youtube.com/channel/UCNykZbGTFX4h6Q_yMCS72IA" target="_blank">
                <img class="button-icon" src="./assets/images/icons/youtube-brands.svg" alt="youtube-brands.svg">
              </a>
            </div>
            <div class="socials-list-item">
              <a href="https://t.me/astmarket_com" target="_blank">
                <img class="button-icon" src="./assets/images/icons/telegram-brands.svg" alt="telegram-brands.svg">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="section grey">
        <div class="container">
          <h3 class="section-title">Мобильное приложение АстМаркет</h3>
          <div class="socials-list app">
            <div class="socials-list-item">
              <a href="https://astmarket.com/app" target="_blank">
                <img class="button-icon" src="./assets/images/icons/app-store-ios-brands.svg" alt="app-store-ios-brands.svg">
              </a>
            </div>
            <div class="socials-list-item">
              <a href="https://astmarket.com/app" target="_blank">
                <img class="button-icon" src="./assets/images/icons/google-play-brands.svg" alt="google-play-brands.svg">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <h3 class="section-title">Мы на маркетплейсах</h3>
          <a href="https://texnikaroom.ru/" target="_blank" class="link">
            <img class="icon" src="./assets/images/icons/link-solid.svg" alt="link-solid.svg">
            <span>https://texnikaroom.ru/</span>
          </a>
        </div>

      </div>
      <div class="section grey">
        <div class="container">
          <h3 class="section-title">Остались вопросы? Напишите нам!</h3>
          <div class="messengers">
            <div class="messengers-icons">
              <img class="icon" src="./assets/images/icons/phone-solid.svg" alt="phone-solid.svg">
            </div>
            <div class="messengers-text">
              <p>+7 (800) 700-51-44</p>
            </div>
          </div>
          <div class="messengers">
            <div class="messengers-icons">
              <img class="icon" src="./assets/images/icons/telegram-brands.svg" alt="telegram-brands.svg">
              <img class="icon" src="./assets/images/icons/whatsapp-brands.svg" alt="whatsapp-brands.svg">
            </div>
            <div class="messengers-text">
              <p>+7 (988) 060-01-50</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "assets/styles/styles";
@import "assets/styles/vars";

.content-holder {
  width: 100%;
  padding: 35px 0 0;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.section {
  width: 100%;
  padding: 45px 0;
  background-color: $white;
  &-title {
    margin-bottom: 20px;
    font-size: 24px;
  }
  &.grey {
    background-color: $grey;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 15px;
  }
}

.logo {
  width: 202px;
  margin-bottom: 30px;
  & img {
    width: 100%;
  }
}

.brands{
  &-list {
   width: 100%;
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   gap: 15px;
   &-item {
     width: calc(100% / 2 - 15px);
     padding: 15px;

     a {
       &:hover {
         opacity: .85;
       }
     }

     & img {
       width: 100%;
     }
   }
  }
  &-footer {
    margin-top: 15px;
  }
}

.jaco-logo {
  background-color: #6C9A25;
}

.link {
  color: #cf0028;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  &:hover {
    text-decoration: none;
  }
  & .icon {
    width: 26px;
    height: 26px;
    margin-right: 15px;
  }
}

.socials-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &-item {
    width: calc(100% / 4);
    padding: 15px;

    &:hover {
      opacity: .75;
    }
    & .button-icon {
      width: 100%;
      height: 100%;
      max-width: 64px;
      max-height: 64px;
    }
  }
}

.messengers {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &-icons {
    margin-right: 5px;
    display: flex;
    & .icon {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }
  &-text {
    font-size: 18px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.button-icon {
  cursor: pointer;
}

@media(min-width: 425px) {
  .section-title {
    font-size: 28px;
  }
}

@media(min-width: 576px) {
  .content-holder {
    padding: 60px 0 0;
  }
  .container {
    max-width: 540px;
  }
  .section {
    padding: 60px 0;
    &:last-child {
      padding-bottom: 30px;
    }
  }
  .logo {
    width: 202px;
    margin-bottom: 30px;
  }
  .brands-list {
    gap: 15px;
    &-item {
      width: calc(100% / 3 - 15px);
      padding: 20px;
    }
  }
  .socials-list {
    width: 80%;
    min-width: calc(calc(64px + 15px * 2) * 4);
    justify-content: space-between;
    &-item {
      width: fit-content;
      padding: 15px;
      & .button-icon {
        width: 64px;
        height: 64px;
      }
    }
    &.app {
      width: 30%;
      min-width: calc(calc(64px + 15px * 2) * 2);
    }
  }
}

@media(min-width: 992px) {
  .container {
    max-width: 960px;
  }
  .section-title {
    margin-bottom: 20px;
    font-size: 36px;
  }
  .logo {
    width: 202px;
    margin-bottom: 45px;
  }
  .brands-list {
    width: 80%;
    gap: 30px;
    &-item {
      width: calc(100% / 3 - 30px);
    }
  }
  .socials-list {
    width: 45%;
    &.app {
      width: 20%;
    }
  }
  .link {
    font-size: 24px;
    & .icon {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }
  }
  .messengers {
    margin-bottom: 20px;
    &-icons {
      margin-right: 15px;
      & .icon {
        width: 32px;
        height: 32px;
      }
    }
    &-text {
      font-size: 24px;
    }
  }
}

@media(min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
</style>
